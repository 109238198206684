import './App.css';
import React, { useEffect, useState, useRef } from 'react';
import * as faceapi from 'face-api.js';
import iziToast from 'izitoast';
import Webcam from 'react-webcam';

const img_expressions = 
[
  'imgs/face-expressions/1.png',
  'imgs/face-expressions/2.png',
  'imgs/face-expressions/3.png',
  'imgs/face-expressions/4.png',
  'imgs/face-expressions/5.png'
];

const questions = [
  'Apakah pendaftaran di rumah sakit ini mudah?',
  'Apakah biaya rawat inap di rumah sakit ini terjangkau?',
  'Apakah fasilitas rawat inap di rumah sakit ini lengkap?',
  'Apakah perawat di rumah sakit ini berpengalaman dan ramah?'
];

const getRandomPosition = () => 
{
  const imgWidth = 100; // Assuming the image width is 100px
  const imgHeight = 100; // Assuming the image height is 100px
  const homePageContent = document.querySelector('.homePage-content');
  const homePageContentHeight = homePageContent ? homePageContent.offsetHeight : 0;
  const homePageContentOffset = homePageContent ? homePageContent.offsetTop : 0;

  let x, y;
  let attempts = 0;
  const maxAttempts = 100;
  do {
    x = Math.floor(Math.random() * (window.innerWidth - imgWidth));
    y = Math.floor(Math.random() * (window.innerHeight - imgHeight));
    attempts++;
  } while (y >= homePageContentOffset && y < homePageContentOffset + homePageContentHeight && attempts < maxAttempts);

  return { x, y };
};

const ImageComponent = ({ src }) => 
{
  const [position, setPosition] = useState(getRandomPosition());

  useEffect(() => {
    const interval = setInterval(() => {
      setPosition(getRandomPosition());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <img
      src={src}
      alt="face expression"
      style={{
        width: '70px',
        height: '70px',
        position: 'absolute',
        left: position.x,
        top: position.y,
        transition: 'left 0.5s, top 0.5s'
      }}
    />
  );
};

const App = () => 
{
  const [currentImageIndices, setCurrentImageIndices] = useState([0, 1, 2]);
  const [pageActive, setPageActive] = useState('home');
  const [yourExpression, setYourExpression] = useState('');
  const [lastExpression, setLastExpression] = useState('');

  const [alreadyLoaded, setAlreadyLoaded] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [redirectTimer, setRedirectTimer] = useState(3);
  const [coolingDown, setCoolingDown] = useState(false);

  const canvasRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndices((prevIndices) => prevIndices.map(index => (index + 1) % img_expressions.length));
    }, 700 + Math.random() * 2500);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => 
  {
    if (pageActive === 'game') 
    {
      const canvasElement = canvasRef.current;
      const canvasCtx = canvasElement.getContext('2d');

      const initializeFaceApi = async () => {
        await faceapi.nets.tinyFaceDetector.loadFromUri('/models');
        await faceapi.nets.faceExpressionNet.loadFromUri('/models');

        let lastFrameTime = 0;
        const processFrame = async (first_call) => 
        {
          const videoElement = document.querySelector('.webcam');
          if (videoElement && videoElement.readyState >= 2) 
          {
            const detections = await faceapi.detectSingleFace(videoElement, new faceapi.TinyFaceDetectorOptions()).withFaceExpressions();
  
            canvasElement.width = videoElement.videoWidth;
            canvasElement.height = videoElement.videoHeight;

            canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
            canvasCtx.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);
            
            if (first_call){
              console.log('first call');
              setAlreadyLoaded(true);
            }

            if (detections && detections.expressions) 
            {
              const expressions = detections.expressions;
              const maxExpression = Object.keys(expressions).reduce((a, b) => expressions[a] > expressions[b] ? a : b);

              if (performance.now() - lastFrameTime > 500) {
                if (maxExpression !== 'neutral' && maxExpression !== 'happy' && maxExpression !== 'sad') {
                  setYourExpression('Ekspresi tidak dikenali');
                } else {
                  setYourExpression(maxExpression);
                }

                lastFrameTime = performance.now();
              }
            }
          }

          requestAnimationFrame(processFrame);
        };

        processFrame(true);
      };

      initializeFaceApi();

      return () => {
        const videoElement = document.querySelector('.webcam');
        if (videoElement) {
          videoElement.srcObject.getTracks().forEach(track => track.stop());
        }
      };
    }

    if (pageActive === 'thanks') 
    {
      const interval = setInterval(() => {
        setRedirectTimer((prevTimer) => {
          prevTimer = prevTimer - 1;
          if (prevTimer === 0) {
            window.location.href = '/';
          }
          return prevTimer;
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [pageActive]);

  const handleNextQuestion = () => 
  {
    if (yourExpression === 'Ekspresi tidak dikenali') {
      iziToast.warning({
        title: 'Gagal',
        message: 'Kamu hanya bisa submit ekspresi sedih, netral atau tersenyum!'
      });
      return;
    }

    var toast = document.querySelector('.iziToast'); // Selector of your toast
    if(toast) {
      iziToast.hide({}, toast);
    }

    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);

    if (currentQuestionIndex === questions.length - 1) {
      setPageActive('thanks');
    }else{
      setLastExpression(yourExpression);
      setCoolingDown(true);

      setTimeout(() => {
        setCoolingDown(false);
      }, 1000);
    }
  };

  return (
    <div className="App">
      {pageActive === 'home' && (
      <div id="homePage">
        <div className="homePage-content">
          <h1 className="app-title">Survei Kekinian</h1>

          {currentImageIndices.map(index => (
            <ImageComponent key={index} src={img_expressions[index]} />
          ))}

          <p>
            Halo, terima kasih mau meluangkan waktu untuk mengisi survei ini.
            Cara isi nya gampang ko, kamu tinggal submit ekspresi wajah kamu saat di ajukan pertanyaan.
          </p>

          <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
            <tbody>
              <tr>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Tidak suka</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Raut wajah sedih</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Neutral</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Tidak ber-ekspresi</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Suka</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Senyum</td>
              </tr>
            </tbody>
          </table>

          <button onClick={() => setPageActive('game')} className="start-button">
            Mulai Survei
          </button>
        </div>
      </div>
      )}

      {pageActive === 'game' && (
        <div id="gamePage">
          <Webcam className="webcam" audio={false} style={{visibility: 'hidden'}} />

          <div className="waiting-text" style={{ display: (alreadyLoaded ? 'none' : 'block')}}>
            <h2>Sedang memuat...</h2>
          </div>

          <div id="gamePage-content" style={{ display: (alreadyLoaded ? 'block' : 'none')}}>
            <canvas ref={canvasRef}></canvas>

            <div id="question-container">
              <p className="question">{questions[currentQuestionIndex]}</p>
              <button onClick={handleNextQuestion} className="submit-button" style={{ opacity: coolingDown ? 0.5 : 1 }}>
                {currentQuestionIndex === questions.length - 1 ? 'Selesai' : 'Selanjutnya'}
              </button>
            </div>

            <span id="your-expression" style={{ opacity: coolingDown ? 0.5 : 1 }}>
              {!coolingDown && yourExpression === 'sad' && <img src="imgs/face-expressions/sad.png" alt="sad emoticon" />}
              {!coolingDown && yourExpression === 'neutral' && <img src="imgs/face-expressions/neutral.png" alt="neutral emoticon" />}
              {!coolingDown && yourExpression === 'happy' && <img src="imgs/face-expressions/happy.png" alt="happy emoticon" />}
              {!coolingDown && yourExpression === 'Ekspresi tidak dikenali' && <img src="imgs/face-expressions/question.png" alt="question emoticon" />}

              {coolingDown && <img src={`imgs/face-expressions/${lastExpression}.png`} alt={`${lastExpression} emoticon`} />}
            </span>
          </div>
        </div>
      )}

      {pageActive === 'thanks' && (
        <div id="thanksPage">
          <div className="thanksPage-content">
            <img src="imgs/check-mark.png" alt="check mark" />
            <h1>Terima kasih!</h1>
            <p>
              Survei kamu sudah selesai, jangan lupa untuk melakukan cekupan tangan ya!
            </p>
            <small> <em> akan mengalihkan ke halaman utama dalam {redirectTimer} detik </em> </small>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;